<template>
  <div class="pl-6 pr-3" style="width: 100%">
    <v-row dense no-gutters>
      <v-col cols="12">
        <v-alert dark color="secondary" :data-cy="$lang.hints.gdprHint">{{ $lang.hints.gdprHint }}</v-alert>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12">
        <v-expansion-panels flat color="surface">
          <template v-for="(type, i) in gdprTypes">
            <v-expansion-panel
              :key="i"
            >
              <v-expansion-panel-header>
                {{ $lang.routes[type] }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <Table
                  :key="tableKey"
                  :items="gdpr[type]"
                  :type="type"
                  :loading="loading"
                  :all-types="gdprTypes"
                  @fetchGdpr="fetchGdpr($event)"
                ></Table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './GdprDatatable'
import {
  cronTriggersGdprReportUsingGET as getGdprCronTrigger,
  modulesGdprReportUsingGET as getGdprModules,
  processCredentialsGdprReportUsingGET as getGdprProcessCredentials,
  processesGdprReportUsingGET as getGdprProcesses,
  restTriggersGdprReportUsingGET as getGdprRestTrigger,
  messagingTriggersGdprReportUsingGET as getGdprMessagingTrigger,
  librariesGdprReportUsingGET as getGdprLibraries,
  entitiesGdprReportUsingGET as getGdprEntities
} from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  components: {
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      tableKey: 0,
      loading: false,
      gdpr: {
        gdprCronTrigger: [],
        gdprRestTrigger: [],
        gdprModules: [],
        gdprProcessCredentials: [],
        gdprProcesses: [],
        gdprMessagingTrigger: [],
        gdprLibraries: [],
        gdprEntities: []
      },
      gdprTypes: [
        'gdprCronTrigger',
        'gdprMessagingTrigger',
        'gdprRestTrigger',
        'gdprProcesses',
        'gdprProcessCredentials',
        'gdprLibraries',
        'gdprEntities',
        'gdprModules'
      ]
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.addSnackbar({
        message: this.$route.params.message,
        timeout: 5000,
        color: 'success'
      })
    }
    this.gdprTypes.forEach((type) => this.fetchGdpr(type))
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    async fetchGdpr(type) {
      this.loading = true

      let res = null

      try {

        switch (type) {
        case 'gdprEntities':
          res = await getGdprEntities()
          break
        case 'gdprCronTrigger':
          res = await getGdprCronTrigger()
          break
        case 'gdprRestTrigger':
          res = await getGdprRestTrigger()
          break
        case 'gdprModules':
          res = await getGdprModules()
          break
        case 'gdprProcessCredentials':
          res = await getGdprProcessCredentials()
          break
        case 'gdprProcesses':
          res = await getGdprProcesses()
          break
        case 'gdprMessagingTrigger':
          res = await getGdprMessagingTrigger()
          break
        case 'gdprLibraries':
          res = await getGdprLibraries()
          break
        default:
          res = null
        }

        if (res && res.status !== 200) {
          this.loading = false

          return
        }

        this.gdpr[type] = res && res.data ? res.data.data : []
        this.loading = false

      } catch (err) {
        this.loading = false
      }
    }
  }
}
</script>
