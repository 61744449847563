<template>
  <v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      class="elevation-0 background-transparent"
      :items-per-page="25"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
      style="width: 100%"
    >
      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex">
          <v-btn color="primary" class="mx-0 button-default-width" :to="{ name: linkResolve[type], params: { id: item.id } }" target="_blank">{{ $lang.actions.open }}</v-btn>
        </div>
      </template>
    </v-data-table>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    allTypes: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      lock: true,
      headers: [
        { text: this.$lang.header.id, value: 'id' },
        { text: this.$lang.labels.name, value: 'name' },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      linkResolve: {
        gdprCronTrigger: 'cronEdit',
        gdprRestTrigger: 'restEdit',
        gdprModules: 'moduleEdit',
        gdprProcessCredentials: 'credentialEdit',
        gdprProcesses: 'processEdit',
        gdprMessagingTrigger: 'messagingEdit',
        gdprLibraries: 'libraryEdit'
      }
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userSettings'])
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.allTypes.forEach((x) => this.$emit('fetchGdpr', x))
    })
  },
  created() {
    this.$emit('fetchGdpr')
    setTimeout(() => this.lock = false, 100)
  },
  methods: {
  }
}
</script>
